
<v-navigation-drawer
  v-model="drawer"
  fixed
  right=""
  height="100%"
  temporary
  floating
  width="350"
>
  <div>
    <div class="pa-5 relative">
      <v-app-bar class="pt-1" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0 text-h6">Notifications</h6>

          <v-btn
            icon
            color
            @click.stop="drawer = !drawer"
          >
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <!-- <div class="heading-label pa-0 mb-8">Notifications</div> -->

      <div v-for="(item, index) in items" :key="index">
        <div class="d-flex justify-space-between mb-8">
          <div class="d-flex">
            <v-avatar size="40" class="mr-2" :color="item.iconColor">
              <v-icon :class="item.iconColorText">{{ item.iconName }}</v-icon>
            </v-avatar>
            <div>
              <p class="font-weight-medium body-2 ma-0">
                {{ item.title }}
              </p>
              <div>
                <span class="text--disabled">
                  On
                  <a href="#" class="caption">Porject alpha</a>
                </span>
                <div class="px-4 py-2 d-flex align-center rounded">
                  <v-img
                    width="40"
                    height="40"
                    class="h-auto"
                    :src="item.file"
                  ></v-img>
                  <span class="caption font-weight-semi">
                    <a class="link-alt" href="#">{{ item.fileName }}</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span class="text--disabled caption">{{ item.time }}</span>
          </div>
        </div>
        <v-divider class="py-2"></v-divider>
      </div>
    </div>
  </div>

  <template #append>
    <div class="my-4 mx-4">
      <base-hover-button
        text="View All Notifications"
        block
        bg-color="primary lighten-5 primary--text"
      />
    </div>
  </template>
</v-navigation-drawer>
