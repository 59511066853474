import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { themePreset } from "../../src/themeConfig";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: themePreset.theme,
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
