const routes = {
  dashboard: {
    path: "/",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/Dashboard.vue")
  },
  qbmanager: {
    path: "/qbmanager",
    name: "QBmanager",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/QBManager.vue")
  },
  qbdetail: {
    path: "/qbmanager/:qbId",
    props: true,
    name: "QBdetail",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/QBDetail.vue")
  },
  plans: {
    path: "/plans",
    name: "Plans",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/PlansManager.vue")
  },
  payments: {
    path: "/payments",
    name: "Payments",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/PaymentsManager.vue")
  },
  notices: {
    path: "/notices",
    name: "Notices",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/Notices.vue")
  },
  about: {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../pages/AboutView.vue")
  },
  forms: {
    path: "/forms",
    name: "Forms",
    meta: { noAuth: true },
    component: () => import("../components/form_sampler/FormulateSpecimens.vue")
  },
  login: {
    path: "/login",
    name: "Login",
    meta: { noAuth: true },
    component: () => import("../pages/Login.vue")
  }
};

export default routes;
