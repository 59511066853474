
<v-btn
  :block="block"
  :class="[bgColor, textColor, textHover, bgHover, customClass]"
  class="shadow-sm"
  :small="small"
  :x-small="xSmall"
  :large="large"
  :color="color"
  :depressed="depressed">
  <v-icon
    v-if="iconName"
    :small="smallIcon"
    class="transition-none"
    :class="[iconMr]">
    {{ iconName }}
  </v-icon>
  {{ text }}
</v-btn>
