
<v-app id="inspire">
  <!-- App Bar  -->
  <app-bar
    @update:toggle-navigation-drawer="$refs.navigationDrawer.toggleDrawer()"
    @update:toggle-notification-drawer="
      $refs.notificationDrawer.toggleDrawer()
    " />

  <!-- Navigation Drawer  -->
  <navigation-drawer ref="navigationDrawer" />

  <!-- Notification Drawer  -->
  <notification-drawer ref="notificationDrawer" />

  <v-main style="background: #f2f3f8">
    <router-view></router-view>
  </v-main>
</v-app>
