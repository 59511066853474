
<v-navigation-drawer
  v-model="drawer"
  :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint"
  app>
  <v-list-item class="ma-0 pa-0 primary white--text">
    <v-list-item-content>
      <!-- <v-list-item-title class="text-h5 font-weight-bold text-center">
          <p class="ma-0 pa-0 text-h4">&#923;NUVI</p>
        </v-list-item-title> -->
      <v-container>
        <v-row>
          <v-col class="pa-0">
            <v-img max-width="132px" class="ml-4" :src="logo"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-list-item-content>
  </v-list-item>

  <!-- <v-list-item class="ma-0 pa-0">
    <v-list-item-content>
      <v-list-item-title class="text-h5 font-weight-bold text-center">
        <p>Employer Portal</p>
      </v-list-item-title>
      <v-divider></v-divider>
    </v-list-item-content>
  </v-list-item> -->

  <v-list nav>
    <v-list-item v-for="item in items" :key="item.title" :to="item.to">
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-navigation-drawer>
