
<v-app-bar app color="primary" dark>
  <v-app-bar-nav-icon
    v-if="$vuetify.breakpoint.mobile"
    @click="$emit('update:toggle-navigation-drawer')"></v-app-bar-nav-icon>

  <v-app-bar-title class="title pl-2">
    <div v-if="$vuetify.breakpoint.mobile">
      <v-img max-width="132px" :src="logo"></v-img>
    </div>
  </v-app-bar-title>

  <v-spacer></v-spacer>

  <v-text-field
    v-model="search"
    placeholder="Search"
    class="expanding-search mt-6 mr-5"
    :class="{ closed: searchClosed && !search }"
    prepend-inner-icon="mdi-magnify"
    dense
    clearable
    filled
    @focus="searchClosed = false"
    @blur="searchClosed = true"></v-text-field>

  <v-badge
    bordered
    overlap
    content="3"
    color="red"
    offset-x="22"
    offset-y="22">
    <v-btn icon @click="$emit('update:toggle-notification-drawer')">
      <v-icon>mdi-bell</v-icon>
    </v-btn>
  </v-badge>

  <v-btn icon>
    <v-icon>mdi-dots-vertical</v-icon>
  </v-btn>
</v-app-bar>
