import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./RouteConstants";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes: [
    routes.dashboard,
    routes.qbmanager,
    routes.qbdetail,
    routes.plans,
    routes.payments,
    routes.notices,
    routes.about,
    routes.forms,
    routes.login
  ]
});

router.beforeEach(function (to, from, next) {
  // If the route isn't a noAuth, token or expiration are not present, or expiration is in the past then the user is not logged in
  if (
    !to.meta.noAuth &&
    (!localStorage.accessToken ||
      !localStorage.tokenExpirationDate ||
      +localStorage.tokenExpirationDate < new Date().getTime())
  ) {
    console.log("Auth failed navigating to /login");
    next("/login");
  } else {
    console.log(`Auth passed navigating to ${to.name}`);
    next();
  }
});

export default router;
