import Vue from "vue";
import VueCompositionAPI, { createApp, h } from "@vue/composition-api";

import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import formulate from "@braid/vue-formulate";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { VueQueryPlugin } from "vue-query";

const instance = axios.create({
  // baseURL: 'https://rest.anuvi.io'
});

const instanceUserApi = axios.create({
  baseURL: import.meta.env.VITE_REST_URL
});
instanceUserApi.defaults.headers.common["Authorization"] =
  "Token" + localStorage.getItem("authToken");

Vue.prototype.$axios = instance;
Vue.prototype.$axiosApiUser = instanceUserApi;

Vue.use(VueCompositionAPI);
Vue.use(formulate);
Vue.use(VueApexCharts);
Vue.use(VueQueryPlugin);

const app = createApp({
  router,
  vuetify,
  // allows us to inject('router') in a setup() child...
  provide: { router: router, axios: instance, axiosApiUser: instanceUserApi },
  render: () => h(App)
});

app.mount("#app");
